import React from 'react';

import SEO from "components/Seo";
import Header from "components/Header";
import Footer from "components/Footer";

import LayoutMain from "components/layout/layoutMain";
import LayoutPanel from "components/layout/layoutPanel";

import Panel from "components/common/Panel";
import Image from 'components/common/Image';
import BlogCarousel from 'components/cards/BlogCarousel';
import panelData from 'constants/people/panel';
import testimonialsData from 'constants/people/testimonials';
import SimpleSlider from 'components/common/SimpleSlider';

import Link from 'components/common/Link';

import 'styles/pages/sustainability.css';

// asset images
// https://www.gatsbyjs.com/docs/how-to/images-and-media/importing-assets-into-files/
import heroBanner from 'images/people/hero-eggs.png';
import heroWescef from 'images/people/wescef-thanks.png';

function PeoplePage() {

	return (
		<>
            <SEO title="Our People | Kleenheat"
            	 description="We recognise that people play an essential part in achieving its objectives & are committed to recognising, developing, training & rewarding its people"
        	/>
            <Header />
            <LayoutMain>

            	{/* hero banner */}
            	<LayoutPanel background="mer-bg--primary-cyan"                 
			                 padding="mer-pt-lg pb-sm-5"
			                 theme="mer-theme--cyan">
			        <div className="container">
			            <div className="row mer-panel mer-panel--layout-reverse justify-content-center">
		                    <div className="col-10 col-sm-7 col-lg-6 mer-text--size-lg order-2">

                                <div className="content">
                                    <h2>Our people are good eggs</h2>
                                    <p>They're us, and they're at the heart of all we do. That's why we support our people's dreams and work closely with the communities where they live.</p>

									<div className="mer-panel__actions mer-button-group">
										<Link text="Careers"
											link="/about/people/careers"
											linkClass="mer-button mer-button--primary" />

										<Link text="Our values"
											link="#ourvalues"
											linkClass="mer-button mer-button--secondary" />
									</div>
                                </div>
                            </div>
                            <div className="col-8 col-sm-5 col-lg-5 offset-lg-1 mer-panel__images order-1 mt-3 mb-n5 mt-lg-0 p-lg-0">
                                <img src={heroBanner} 
                                     className="mer-panel__image mb-lg-n5"
                                     alt="Hero eggs" />
                            </div>
                            
			            </div>
					</div>
			    </LayoutPanel>

			    {/* work with us */}
		    	<LayoutPanel background="mer-bg--gs-grey-lighter"
			                 padding="mer-py-lg"
			                 id="work">
			        <div className="container">
						<div className="row mer-panel align-items-center justify-content-center mer-text--size-lg">
			              	<div className="content col-10 col-sm-7 order-sm-2 offset-sm-1">
								<h2 className="mer-typography--headline3">Work with us</h2>
								<p>Right now, we’re looking for Kleenheat-minded people to join our team. We'd love people who think like us to get on board - and belong to this shared workplace where everyone's welcome and encouraged to make a positive difference. Sound like you? Click below.</p>									

								<div className="mer-panel__actions mer-button-group">
									<Link text="Job opportunities"
										link="/about/people/careers"
										linkClass="mer-button mer-button--primary" />
								</div>
			              	</div>
							<div className="mer-panel__images col-6 col-sm-3 pr-sm-0 order-sm-1">
                                <Image className="mer-panel__image" src="/icons/flowmoji/flowmoji-highfive.svg" alt="Flowmoji highfive" />
						    </div>
			            </div>
			        </div>
			    </LayoutPanel>

		    	{/* our values */}
		    	<LayoutPanel background="mer-bg--ui-light"
			                 padding="mer-py-lg"
							 id="ourvalues">
			        <div className="container">

						<div className="row align-items-center justify-content-center">
	                		<div className="col-10 col-sm-8 mer-text--size-lg">
				                <div className="content text-center">
									<h1 className="mer-color--primary-cyan">Our values</h1>
									<p>We like to keep life light, but we take our values seriously. Sure, they include some big words, and that's because they're a very big deal to us all. And we wouldn't change that.</p>				                	
				                </div>
				            </div>
	                	</div>

			        	<Panel data={panelData} 
			                   id="panel-1"
						/>
                        <Panel data={panelData} 
			                   id="panel-2"
						/>
                        <Panel data={panelData} 
			                   id="panel-3"
						/>
						<Panel data={panelData} 
			                   id="panel-4"
						/>
						<Panel data={panelData} 
			                   id="panel-5"
						/>
			        </div>
		        </LayoutPanel>

				{/* testimonials */}
				{!!(Array.isArray(testimonialsData.content) && testimonialsData.content.length && testimonialsData.content.length>0) &&
				<LayoutPanel background="mer-bg--gs-grey-lighter"
			                 padding="mer-py-lg"
			                 id="testimonials2">
			        <div className="container">
			            <div className="row mer-panel justify-content-center">
			              	<div className="col-10 col-sm-7">
			              		<div className="content mer-text--size-lg text-center">
				                	<h2 className="mer-typography--headline2">In our people's words.</h2>                                    
	          					</div>
			              	</div>
			            </div>
			        </div>
					<SimpleSlider equalHeight={true} 
									data={testimonialsData.content}
									id="testimonials" />	
			    </LayoutPanel>
				}

				{/* diversity */}
				<LayoutPanel background="mer-bg--ui-light"
			                 padding="mer-py-lg">
			        <div className="container">
			            <div className="row mer-panel mer-panel--layout-reverse">		
							<div className="col-10 col-sm-7 col-lg-5 order-sm-2">
								<div className="content mer-text--size-lg">									
									<h2 className="mer-typography--headline2">Diversity in our workplace</h2>
									<p>We embrace differences and celebrate the diverse skills, life experiences and backgrounds that shape our people. We like being inclusive, and we like to challenge thinking around diversity. That’s why we encourage people from all walks of life to apply to work with us.</p>				                	
								</div>
							</div>
							<div className="mer-panel__images col-5 col-sm-4 col-lg-3 mb-sm-0 order-sm-1 offset-sm-1">
                                <Image className="mer-panel__image" src="/icons/flowmoji/flowmoji-diversity.svg" alt="Flowmoji diversity" />
						    </div>					
			            </div>
			        </div>
			    </LayoutPanel>

				{/* reward */}
				<LayoutPanel background="mer-bg--gs-grey-lighter"
			                 padding="mer-py-lg">
			        <div className="container">
			            <div className="row mer-panel mer-panel--layout-reverse">

			              	<div className="col-10 col-sm-7 col-lg-6 order-sm-1 offset-sm-1">
			              		<div className="content mer-text--size-lg">
				                	<h2 className="mer-typography--headline2">We recognise and reward people.</h2>
                                    <p>When our people go above and beyond in the workplace, colleagues can shine a light on them. The WesCEF Thanks awards is a program that recognises and rewards those who make a difference.</p>
									<p>Our people are encouraged to nominate a person or team from Kleenheat, another WesCEF business or shared services team for an award. It's a great way to thank them for inspiring behaviour and a winning contribution.</p>
	          					</div>
			              	</div>

							<div className="mer-panel__images col-5 col-sm-4 col-lg-3 mb-sm-0 order-sm-2">
								<img src={heroWescef} 
                                     className="mer-panel__image"
                                     alt="Hero Wescef" />
						    </div>	
			            </div>
			        </div>
			    </LayoutPanel>

				<BlogCarousel id="stories"
							title="Kleenheat in the community"
							titleCol="col-10"							
							apiQuery="?tag=community&posts_per_page=5"
							ctaText="Discover more 'Community' content" 
			              	ctaLink="/blog/community"  
				/>

		    </LayoutMain>
			<Footer />
		</>
	)
}

export default PeoplePage;
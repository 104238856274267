import React from 'react';
import Slider from 'react-slick';

function SimpleSlider (props) {

    let carouselIsHeightEqual = props.equalHeight !== undefined && props.equalHeight  ? ' mer-slick-slider--height-equal' : '';
    let defaultCarouselClass = 'container';
    let carouselClass = defaultCarouselClass + carouselIsHeightEqual;
    
    const settings = {
        
        arrows: false,
        dots: true,
        infinite: true,
        swipeToSlide: true,
        draggable: true,

        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        // centerMode: true,
        centerPadding: '128px',
  
        dotsClass: 'd-flex slick-dots',  
        className: carouselClass,

        responsive: [
            {
                breakpoint: 448,
                settings: {
                    infinite: false,
                    slidesToShow: 1,
                    centerMode: false,
                    // centerPadding: '48px'
                }
            }
        ]
    };

    return (
        <React.Fragment>

            <Slider {...settings}>
               {props.data.map((element, index) => {
                    return (
                        <div className="container" key={index}>
                            <div className="row justify-content-center">
                                <div className="col-10 col-sm-8 col-lg-6">
                                    {element.textHtml && 
                                        <div key={`desc-${index}`} 
                                            dangerouslySetInnerHTML={ {__html: Array.isArray(element.textHtml) ? element.textHtml.join('') : element.textHtml} }></div>
                                    }
                                </div>
                            </div>
                        </div>
                    );

                    })
                }              
               
            </Slider>
        </React.Fragment>
    );
}

export default SimpleSlider;